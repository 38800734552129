(function ($) {
    "use strict";

    $('body').scrollspy({target: ".navbar", offset: 50});

    $('.partners-link').magnificPopup({
        type:'image',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass ="mfp-zoom-in";
            }
        },
        gallery: {
            enabled: true, // set to true to enable gallery

            preload: [0,1], // read about this option in next Lazy-loading section

            navigateByImgClick: true,

            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

        },
        closeOnContentClick: true,
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

// Add smooth scrolling on all links inside the navbar
    $(".navbar a, .btn-consultation").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {

            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function(){

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });

        } // End if

    });

    $('body').on('click', '.navbar-toggle-button', function () {
        var $collapse = $($(this).data('target'));
        if ($collapse.hasClass('in')) {
            $(this).removeClass('open');
        } else {
            $(this).addClass('open');
        }
    });
    $('.feature-col .text').hyphenate('ru');


    $('[data-form]').on('afterValidateAttribute', function (e, elem, errors) {
        var $elem = $('#' + elem.id).parents('.form-group');
        console.log(elem.id, errors);
        if (errors.length > 0) {
            var msg = errors.join('<br>');
            if (typeof $elem.data('original-title') != "undefined") {
                $elem.attr('title', msg).tooltip('fixTitle').tooltip('setContent').tooltip('show');
            } else {
                $elem.tooltip({
                    title: msg,
                    placement: 'top',
                    trigger: 'manual',
                    html: true,
                    container: $elem,
                });
                $elem.tooltip('show');
            }
        } else {
            $elem.tooltip('destroy');
        }
    });

    $(document).on('pjax:success', function () {
        field.init();
    });

    var field = {
        init: function () {
            $('[data-phone]').mask("+7 (999) 999-99-99", {placeholder: "+7 (___) ___-__-__"});
        }
    };
    field.init();

})(jQuery);